import { CreateBiometric } from '../@types/biometric';
import { Biometric } from '../models/biometric';
import { api } from '../providers/base-api';
import { biometricApi } from '../providers/biometric-api';

export async function createBiometric(
  data: CreateBiometric,
): Promise<Biometric> {
  const response = await api.post<Biometric>(`/biometric`, data);
  return response.data;
}

export async function transferBiometric(
  accessCode: string,
): Promise<Biometric> {
  const response = await api.get<Biometric>(
    `/biometric/access-code/${accessCode}`,
  );
  return response.data;
}

export async function findBiometricByUser(userId: number): Promise<Biometric> {
  const response = await api.get<Biometric>(`/biometric/${userId}`);
  return response.data;
}

export async function loadToMemoryDevice(
  biometrics: any[],
): Promise<{ message: string; success: boolean }> {
  const response = await biometricApi.post('/load-to-memory', biometrics);
  return response.data;
}

export function identificationBiometric() {
  return biometricApi.get('/identification');
}

export async function checkBiometricDevice(): Promise<{
  serial: string;
  success: boolean;
}> {
  const response = await biometricApi.get('/device-unique-id');
  return response.data;
}

export function createBiometricService() {
  return biometricApi.get('/capture-hash');
}

export function totalInDevice() {
  return biometricApi.get('/total-in-memory');
}

export async function getAllBiometrics() {
  const response = await api.get<Biometric[]>(`/biometric`, {
    headers: {
      biometric: btoa(
        `${process.env.REACT_APP_BIOMETRIC_HASH}/${new Date().getTime()}`,
      ).toString(),
    },
  });
  return response.data;
}

export function deleteAllFromDevice() {
  return biometricApi.get('/delete-all-from-memory');
}
