import { Flex } from '@mantine/core';
import { IconHome } from '@tabler/icons';
import { useAuth } from '../../hooks/useAuth';
import { UserRole, UserType } from '../../models/user';
import { AppRoutePaths } from '../../utils/enums/routes';
import { rootNavigate } from '../custom-router';
import { NavButton } from '../nav-button';
import { CashFlowLink } from './links/cash-flow';
import { EmployeeTransportLink } from './links/employee-transport';
import { UserLink } from './links/user-link';
import { HourHistoryLink } from './links/work-history-link';
import { CompanyLink } from './links/company-link';
import { BankHoursLink } from './links/bank-hours-link';
import { WorkScaleLink } from './links/work-scale-link';
import { WorkAlertsLink } from './links/work-alerts';

interface SidebarProps {
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Sidebar({ setOpened }: SidebarProps) {
  const { user } = useAuth();
  const canViewCashFlow =
    user?.user.role === UserRole.MANAGER_CREATOR ||
    user?.user.type === UserType.MASTER;

  function navigate(to: string) {
    rootNavigate(to);
    setOpened(false);
  }

  return (
    <>
      <Flex direction="column">
        <NavButton
          text="Inicio"
          onClick={() => navigate(AppRoutePaths.HOME)}
          icon={<IconHome size={16} />}
          color={'gray'}
        />
        <UserLink navigate={navigate} />
        <CompanyLink navigate={navigate} />
        <HourHistoryLink navigate={navigate} />
        {canViewCashFlow && <CashFlowLink navigate={navigate} />}
        <EmployeeTransportLink navigate={navigate} />
        <BankHoursLink navigate={navigate} />
        <WorkScaleLink navigate={navigate} />
        <WorkAlertsLink navigate={navigate} />
      </Flex>
    </>
  );
}
