import { useFetch } from '../../../hooks/useFetch';
import { DailyWork } from '../../../models/daily-work';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseAddDailyWorkJustificationProps,
  UseAddDailyWorkManuallyProps,
  UseEditAppointmentsProps,
  UseEditDailyWorkJustificationProps,
  UseEditDailyWorkProps,
  UseGenerateUserMirrorProps,
  UseGetLateWorksProps,
} from '../../../utils/types/data/hooks/daily-work';
import { PaginatedResponse } from '../../../utils/types/data/pagination';
import { GetLateWorkResponse } from '../../../utils/types/data/services/daily-work';
import {
  addDailyWorkJustificationRequest,
  addDailyWorkManuallyRequest,
  editAppointmentsRequest,
  editDailyWorkJustificationRequest,
  editDailyWorkRequest,
  generateUserMirrorRequest,
  getFaultWorkRequest,
  getLateWorkRequest,
} from '../../daily-work';

export function useAddDailyWorkManually() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseAddDailyWorkManuallyProps) =>
    fetchData({
      fetcher: () => addDailyWorkManuallyRequest(data),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro ao adicionar horário.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useEditDailyWork() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<DailyWork>();

  const fetch = ({ id, data, onSuccess }: UseEditDailyWorkProps) =>
    fetchData({
      fetcher: () => editDailyWorkRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro ao editar horário.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useAddDailyWorkJustification() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseAddDailyWorkJustificationProps) =>
    fetchData({
      fetcher: () => addDailyWorkJustificationRequest(data),
      onError: (error: any) => {
        errorNotification({
          title: error.response?.data.message,
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGenerateUserMirror() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Blob>();

  const fetch = ({ params, onSuccess }: UseGenerateUserMirrorProps) =>
    fetchData({
      fetcher: () => generateUserMirrorRequest(params),
      onError: async (error: any) => {
        const convertedError = JSON.parse(await error.response.data.text());
        errorNotification({
          title: translateServerHttpErrors(
            { response: { data: convertedError } as any } as any,
            'Erro ao gerar espelho de ponto.',
          ),
          message: 'tente novamente',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useEditDailyWorkJustification() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseEditDailyWorkJustificationProps) =>
    fetchData({
      fetcher: () => editDailyWorkJustificationRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: error.response?.data.message,
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetLateWorks() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<GetLateWorkResponse>>();

  const fetch = ({ query, onSuccess }: UseGetLateWorksProps) =>
    fetchData({
      fetcher: () => getLateWorkRequest(query),
      onError: async (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(
            error,
            'Erro buscar pontos em atraso.',
          ),
          message: 'tente novamente',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetFaultWorks() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<GetLateWorkResponse>>();

  const fetch = ({ query, onSuccess }: UseGetLateWorksProps) =>
    fetchData({
      fetcher: () => getFaultWorkRequest(query),
      onError: async (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro buscar faltas.'),
          message: 'tente novamente',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useEditAppointments() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseEditAppointmentsProps) =>
    fetchData({
      fetcher: () => editAppointmentsRequest(id, data),
      onError: async (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro editar apontamento.'),
          message: 'tente novamente',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
