import {
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  Container,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  Select,
  Text,
  Textarea,
  TextInput,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  IconBus,
  IconCheck,
  IconClock,
  IconDotsVertical,
  IconEdit,
  IconListCheck,
  IconListDetails,
  IconMedicineSyrup,
  IconMessage,
  IconMoneybag,
  IconTableExport,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReceiptUserListItem } from '../../@types/daily-work';
import { EmployeeTransportUserListItem } from '../../@types/employee-transport';
import { CustomCalendar } from '../../components/custom-calendar';
import { CustomDatePicker } from '../../components/custom-date-picker';
import { CustomDateTimePicker } from '../../components/custom-date-time-picker';
import { CustomLoader } from '../../components/custom-loader';
import { Page } from '../../components/page';
import {
  generateDailyWorkReceiptRequest,
  getDailyWorkReceiptUserListRequest,
  getDailyWorksRequest,
  setDailyWorkBankHourRequest,
} from '../../data/daily-work';
import {
  createManyEmployeeTransportRequest,
  listEmployeeTransportUsersRequest,
} from '../../data/employee-transport';
import {
  useAddDailyWorkJustification,
  useAddDailyWorkManually,
  useEditDailyWork,
  useEditDailyWorkJustification,
} from '../../data/hooks/daily-work';
import { getUsersRequest } from '../../data/user';
import { useAuth } from '../../hooks/useAuth';
import { DailyWorkResponse, WorkType } from '../../models/daily-work';
import { User, UserRole, UserType } from '../../models/user';
import { dayjsPlugins, formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import {
  dailyWorkTypeHumanized,
  workJustification,
} from '../../utils/constants/daily-work';
import { userRoleHumanized, userRoleList } from '../../utils/constants/user';
import {
  formatBRL,
  formatDateTime,
  realToUSCash,
  translateServerHttpErrors,
} from '../../utils/helpers';

type EditDailyWorkFormType = {
  begin: Date;
  start_lounch?: Date;
  end_lounch?: Date;
  finish?: Date;
  description?: string;
  isPaid: boolean;
  price: string;
};

type CreateDailyWorkFormType = {
  user: string;
  begin: Date;
  start_lounch?: Date;
  end_lounch?: Date;
  finish?: Date;
  description?: string;
};

type AddDailyWorkJustificationFormType = {
  user: string;
  justification: string;
};

type EditDailyWorkJustificationFormType = {
  user: string;
  begin: Date;
  justification: string;
};

type UserToVT = { id: number; value: string };

type PageModalState =
  | 'confirm-receipt'
  | 'generate-receipt'
  | 'generate-vt'
  | 'edit-daily-work'
  | 'add-daily-work'
  | 'add-work-justification'
  | 'edit-work-justification'
  | null;

function downloadFile(response: Blob, fileName: string) {
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
}

function DateFormInput({
  form,
  formKey,
  label,
}: {
  form: UseFormReturnType<any>;
  formKey: keyof Pick<
    CreateDailyWorkFormType | EditDailyWorkFormType,
    'begin' | 'start_lounch' | 'end_lounch' | 'finish'
  >;
  label: string;
}) {
  return (
    <>
      {form.values[formKey] === undefined ? (
        <>
          <Text>{label}</Text>
          <Button
            variant="subtle"
            onClick={() => form.setFieldValue(formKey, new Date())}
          >
            {form.values?.[formKey]
              ? formatDateTime(form.values[formKey] ?? '')
              : '--'}
          </Button>
        </>
      ) : (
        <Flex>
          <CustomDateTimePicker
            label={label}
            date={form.values[formKey] ?? new Date()}
            onChange={(dateTime) => form.setFieldValue(formKey, dateTime)}
          />
          <Button
            color="red"
            m={0}
            p={4}
            variant="subtle"
            onClick={() => form.setFieldValue(formKey, undefined)}
          >
            <IconX size={16} />
          </Button>
        </Flex>
      )}
    </>
  );
}

export function WorkHistoryList() {
  const { user } = useAuth();
  const [pageLoading, setPageLoading] = useState(false);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [etUserList, setEtUserList] = useState<EmployeeTransportUserListItem[]>(
    [],
  );
  const [receiptUserList, setReceiptUserList] = useState<ReceiptUserListItem[]>(
    [],
  );
  const [selectedUsersToVT, setSelectedUsersToVT] = useState<UserToVT[]>([]);
  const [selectedUsersToReceipt, setSelectedUsersToReceipt] = useState<
    number[]
  >([]);
  const {
    fetch: addDailyWorkManuallyFetcher,
    loading: addDailyWorkManuallyLoader,
  } = useAddDailyWorkManually();
  const { fetch: editDailyWorkFetcher, loading: editDailyWorkLoader } =
    useEditDailyWork();

  const {
    fetch: addDailyWorkJustificationFetcher,
    loading: addDailyWorkJustificationLoader,
  } = useAddDailyWorkJustification();

  const {
    fetch: editDailyWorkJustificationFetcher,
    loading: editDailyWorkJustificationLoader,
  } = useEditDailyWorkJustification();

  const [userList, setUserList] = useState<User[]>([]);
  const [workHistoryList, setWorkHistory] = useState<DailyWorkResponse[]>([]);
  const [selectedDailyWork, setSelectedDailyWork] =
    useState<DailyWorkResponse>();
  const [selectedDailyWorkList, setSelectedDailyWorkList] = useState<
    DailyWorkResponse[]
  >([]);

  const isManagerViewer = user?.user.role === UserRole.MANAGER_VIEWER;
  const isNotMaster = user?.user.type !== UserType.MASTER;

  const formFilter = useForm({
    initialValues: {
      userId: '',
      dateBegin: new Date(),
      dateEnd: new Date(),
      role: '',
    },
  });

  const receiptListForm = useForm({
    initialValues: {
      dateBegin: new Date(),
      dateEnd: new Date(),
      role: 'daily-employee',
    },
  });

  const transportListForm = useForm({
    initialValues: {
      role: 'daily-employee',
    },
  });

  const editDailyWorkForm = useForm<EditDailyWorkFormType>({
    initialValues: {
      begin: new Date(),
      start_lounch: undefined,
      end_lounch: undefined,
      finish: undefined,
      description: '',
      isPaid: false,
      price: '',
    },
  });

  const addDailyWorkForm = useForm<CreateDailyWorkFormType>({
    initialValues: {
      user: '',
      begin: new Date(),
      start_lounch: undefined,
      end_lounch: undefined,
      finish: undefined,
      description: '',
    },
  });

  const addDailyWorkJustificationForm =
    useForm<AddDailyWorkJustificationFormType>({
      initialValues: {
        user: '',
        justification: '',
      },
    });

  const editDailyWorkJustificationForm =
    useForm<EditDailyWorkJustificationFormType>({
      initialValues: {
        user: '',
        begin: new Date(),
        justification: '',
      },
    });

  const handleFilter = useCallback(async (values: typeof formFilter.values) => {
    try {
      setPageLoading(true);
      const response = await getDailyWorksRequest({
        dateBegin: values.dateBegin.toISOString(),
        dateEnd: values.dateEnd.toISOString(),
        user: values.userId ? Number(values.userId) : undefined,
        role: values.role ? (values.role as UserRole) : undefined,
      });
      let accValue = 0;

      response.forEach(
        (item) => (accValue += Number(realToUSCash(item.totalValue)) ?? 0),
      );
      setWorkHistory(response);
      setPageLoading(false);
      setPageTotal(accValue);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar horários.',
        message: 'tente novamente',
      });
    }
  }, []);

  function handleSelectUserToVT(userToVT: UserToVT) {
    const listCopy = selectedUsersToVT;
    const findIndex = selectedUsersToVT.findIndex(
      (item) => item.id === userToVT.id,
    );
    if (findIndex !== -1) {
      listCopy.splice(findIndex, 1);
      setSelectedUsersToVT([...listCopy]);
    } else {
      setSelectedUsersToVT([...selectedUsersToVT, userToVT]);
    }
  }

  function handleSelectUserToReceipt(id: number) {
    const listCopy = selectedUsersToReceipt;
    const findIndex = selectedUsersToReceipt.findIndex((item) => item === id);
    if (findIndex !== -1) {
      listCopy.splice(findIndex, 1);
      setSelectedUsersToReceipt([...listCopy]);
    } else {
      setSelectedUsersToReceipt([...selectedUsersToReceipt, id]);
    }
  }

  function handleSelectDailyWork(dailyWork: DailyWorkResponse) {
    setSelectedDailyWork(dailyWork);
    editDailyWorkForm.setValues({
      begin: dailyWork.begin
        ? dayjsPlugins(dailyWork.begin).toDate()
        : undefined,
      start_lounch: dailyWork.start_lounch
        ? dayjsPlugins(dailyWork.start_lounch).toDate()
        : undefined,
      end_lounch: dailyWork.end_lounch
        ? dayjsPlugins(dailyWork.end_lounch).toDate()
        : undefined,
      finish: dailyWork.finish
        ? dayjsPlugins(dailyWork.finish).toDate()
        : undefined,
      description: dailyWork.description,
      isPaid: dailyWork.isPaid,
      price: formatBRL(dailyWork.price),
    });
    setPageModal('edit-daily-work');
  }

  function handleSelectJustification(dailyWork: DailyWorkResponse) {
    setSelectedDailyWork(dailyWork);
    editDailyWorkJustificationForm.setValues({
      user: dailyWork.username,
      begin: dayjsPlugins(dailyWork.begin).toDate(),
      justification: dailyWork.justification,
    });
    setPageModal('edit-work-justification');
  }

  function handleSelectDates(dates: Date[]) {
    setSelectedUsersToVT([]);
    setSelectedDates(dates);
  }

  async function handleEditDailyWork(values: typeof editDailyWorkForm.values) {
    if (!selectedDailyWork) return;

    editDailyWorkFetcher({
      id: selectedDailyWork?.id,
      data: {
        description: values.description ?? undefined,
        isPaid: values.isPaid,
        price: realToUSCash(values?.price),
        start_lounch: values.start_lounch ?? null,
        end_lounch: values.end_lounch ?? null,
        begin: values.begin ?? null,
        finish: values.finish ?? null,
      },
      onSuccess: () => {
        handleFilter(formFilter.values);
        editDailyWorkForm.reset;
        setPageModal(null);
        successNotification({
          title: 'Horário Atualizado.',
          message: 'tudo certo!',
        });
      },
    });
  }

  async function handleSubmitSetBankHour() {
    if (!selectedDailyWorkList.length) {
      return;
    }

    try {
      setPageLoading(true);
      await setDailyWorkBankHourRequest({
        ids: selectedDailyWorkList.map(({ id }) => id),
      });
      setPageLoading(false);
      setPageModal(null);
      setSelectedDailyWorkList([]);
      handleFilter(formFilter.values);
      successNotification({
        title: 'Usuários adicionados a escala.',
        message: 'tudo certo',
      });
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao adicionar usuário a escala.',
        message: 'tente novamente',
      });
    }
  }

  async function handleDailyWorkReceitpExport(
    values: typeof receiptListForm.values,
  ) {
    try {
      setPageLoading(true);
      const response = await generateDailyWorkReceiptRequest({
        dateBegin: values.dateBegin.toISOString(),
        dateEnd: values.dateEnd.toISOString(),
        users: selectedUsersToReceipt,
      });
      downloadFile(
        response,
        `recibo-diaria-${String(new Date(Date.now()).valueOf()).slice(
          7,
          11,
        )}.pdf`,
      );
      setPageLoading(false);
      setPageModal('confirm-receipt');
      setSelectedUsersToReceipt([]);
      setReceiptUserList([]);
      receiptListForm.reset();
    } catch (error: any) {
      const convertedError = JSON.parse(await error.response.data.text());
      setPageLoading(false);
      errorNotification({
        title: translateServerHttpErrors(
          { response: { data: convertedError } as any } as any,
          'Erro ao gerar recibo de vt.',
        ),
        message: 'tente novamente',
      });
    }
  }

  async function handleGetETUsers(values: typeof transportListForm.values) {
    try {
      setPageLoading(true);
      const response = await listEmployeeTransportUsersRequest({
        role: values.role as UserRole,
      });
      setEtUserList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function handleEmployeeTransportReceitpExport() {
    const formatedDates = selectedDates.map((date) => date.toISOString());
    const userList = selectedUsersToVT.map((item) => ({
      days: formatedDates,
      rechargeValue: item.value,
      user: { id: item.id },
    }));

    if (!userList.length) {
      errorNotification({
        title: 'Erro ao gerar recibo de VT.',
        message: 'necessário selecionar ao menos um usuário.',
      });
      return;
    }

    try {
      setPageLoading(true);
      const response = await createManyEmployeeTransportRequest({
        items: userList,
      });
      downloadFile(
        response,
        `recibo-vt-${String(new Date(Date.now()).valueOf()).slice(7, 11)}.pdf`,
      );
      setPageLoading(false);
      setPageModal('confirm-receipt');
      setSelectedDates([]);
      setSelectedUsersToVT([]);
    } catch (error: any) {
      const convertedError = JSON.parse(await error.response.data.text());
      setPageLoading(false);
      errorNotification({
        title: translateServerHttpErrors(
          { response: { data: convertedError } as any } as any,
          'Erro ao gerar recibo de vt.',
        ),
        message: 'tente novamente',
      });
    }
  }

  async function handleShowVTModal() {
    setPageModal('generate-vt');
  }

  async function handleCloseVTModal() {
    setPageModal(null);
    setSelectedUsersToVT([]);
    setSelectedDates([]);
  }

  async function handleShowDailyWorkModal() {
    setPageModal('generate-receipt');
  }

  async function getUsers() {
    try {
      setPageLoading(true);
      const response = await getUsersRequest();
      setUserList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function handleFilterReceiptList({
    dateBegin,
    dateEnd,
    role,
  }: typeof receiptListForm.values) {
    try {
      setPageLoading(true);
      const response = await getDailyWorkReceiptUserListRequest({
        dateBegin: dateBegin.toISOString(),
        dateEnd: dateEnd.toISOString(),
        role: role ? (role as UserRole) : undefined,
      });
      setReceiptUserList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function handleAddDailyWork(values: typeof addDailyWorkForm.values) {
    await addDailyWorkManuallyFetcher({
      data: {
        description: values.description,
        start_lounch: values.start_lounch,
        end_lounch: values.end_lounch,
        begin: values.begin,
        finish: values.finish,
        user: Number(values.user),
      },
      onSuccess: () => {
        addDailyWorkForm.reset();
        setPageModal(null);
        handleFilter(formFilter.values);
      },
    });
  }

  async function addDailyWordJustification(
    values: typeof addDailyWorkJustificationForm.values,
  ) {
    if (!selectedDates.length) {
      errorNotification({
        title: '',
        message: 'Selecione a data',
      });
      return;
    }

    // Function to order date array
    const formatedDates = selectedDates
      .sort(function (a, b) {
        return a.getTime() - b.getTime();
      })
      .map((date) => date.toISOString());

    await addDailyWorkJustificationFetcher({
      data: {
        date: formatedDates,
        user: Number(values.user),
        justification: values.justification,
      },
      onSuccess: () => {
        addDailyWorkJustificationForm.reset();
        setSelectedDates([]);
        setPageModal(null);
        successNotification({
          title: 'Justificativa adicionada',
          message: 'tudo certo.',
        });
        handleFilter(formFilter.values);
      },
    });
  }

  async function editDailyWordJustification(
    values: typeof editDailyWorkJustificationForm.values,
  ) {
    if (!selectedDailyWork) {
      return;
    }

    await editDailyWorkJustificationFetcher({
      id: selectedDailyWork.id,
      data: {
        begin: values.begin,
        justification: values.justification,
        user: values.user,
      },
      onSuccess: () => {
        editDailyWorkJustificationForm.reset();
        setPageModal(null);
        successNotification({
          title: 'Justificativa editada',
          message: 'tudo certo.',
        });
        handleFilter(formFilter.values);
      },
    });
  }

  async function handleCloseModal() {
    editDailyWorkJustificationForm.reset();
    setSelectedDates([]);
    setSelectedDailyWork(undefined);
    setPageModal(null);
  }

  const RenderDataTable = useMemo(
    () => (
      <DataTable
        selectedRecords={selectedDailyWorkList}
        onSelectedRecordsChange={(dWork) => setSelectedDailyWorkList(dWork)}
        fetching={pageLoading}
        minHeight={500}
        noRecordsText="Sem horários"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        records={workHistoryList}
        columns={[
          {
            accessor: 'type',
            title: '',
            render: ({ type }) => (
              <Badge color={type === WorkType.JUSTIFICATION ? 'green' : ''}>
                {dailyWorkTypeHumanized[type]}
              </Badge>
            ),
          },
          {
            accessor: 'username',
            title: 'Funcionário',
          },
          {
            accessor: 'begin',
            title: 'Entrada',
            render: ({ begin }) =>
              begin ? formatLocale(begin, 'DD/MM/YYYY HH:mm:ss') : '--',
          },
          {
            accessor: 'start_lounch',
            title: 'Inicio Intervalo',
            render: ({ start_lounch }) =>
              start_lounch
                ? formatLocale(start_lounch, 'DD/MM/YYYY HH:mm:ss')
                : '--',
          },
          {
            accessor: 'end_lounch',
            title: 'Fim Intervalo',
            render: ({ end_lounch }) =>
              end_lounch
                ? formatLocale(end_lounch, 'DD/MM/YYYY HH:mm:ss')
                : '--',
          },
          {
            accessor: 'finish',
            title: 'Saída',
            render: ({ finish }) =>
              finish ? formatLocale(finish, 'DD/MM/YYYY HH:mm:ss') : '--',
          },
          {
            accessor: 'workHours',
            title: 'Horas Trabalhadas',
            render: ({ workHours, type }) =>
              type === WorkType.WORK && workHours,
          },
          {
            accessor: 'combined1',
            title: 'Valor da Diária',
            render: ({ price, type }) =>
              type === WorkType.WORK && formatBRL(String(price)),
          },
          {
            accessor: 'totalValue',
            title: 'Valor a Receber',
            render: ({ totalValue, type }) =>
              type === WorkType.WORK && totalValue,
          },
          {
            accessor: 'isBankHour',
            title: 'Escala',
            render: ({ isBankHour, type }) =>
              type === WorkType.WORK && (
                <Badge color={isBankHour ? 'red' : 'blue'}>
                  {isBankHour ? 'Sim' : 'Nâo'}
                </Badge>
              ),
          },
          {
            accessor: 'description',
            title: 'Descrição',
            render: ({ description, justification, type }) =>
              (description || justification) && (
                <Tooltip
                  maw={200}
                  multiline
                  label={type === WorkType.WORK ? description : justification}
                  withArrow
                >
                  <Button variant="subtle" p={0} children={<IconMessage />} />
                </Tooltip>
              ),
          },
          {
            accessor: 'daily-word-edit-menu',
            title: '',
            render: (dailyItem) => (
              <Menu disabled={isNotMaster}>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() =>
                      dailyItem.type === WorkType.JUSTIFICATION
                        ? handleSelectJustification(dailyItem)
                        : handleSelectDailyWork(dailyItem)
                    }
                    icon={<IconEdit size={14} />}
                  >
                    Editar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    ),
    [workHistoryList, selectedDailyWorkList.length],
  );

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Page>
      <CustomLoader
        loading={
          pageLoading ||
          addDailyWorkManuallyLoader ||
          editDailyWorkLoader ||
          addDailyWorkJustificationLoader
        }
      />
      <Flex m={16} direction="column">
        <Flex align="center" justify="end">
          <Menu disabled={isManagerViewer} shadow="md" width={200}>
            <Menu.Target>
              <Button
                mr={8}
                disabled={isManagerViewer}
                rightIcon={<IconTableExport size={18} />}
              >
                Recibos
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Tipos de recibo</Menu.Label>
              <Menu.Item
                onClick={handleShowVTModal}
                icon={<IconBus size={14} />}
              >
                Vale Transporte
              </Menu.Item>
              <Menu.Item
                onClick={handleShowDailyWorkModal}
                icon={<IconMoneybag size={14} />}
              >
                Diárias
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <Button
            disabled={!selectedDailyWorkList.length}
            color="ltpBlue.9"
            leftIcon={<IconListCheck />}
            onClick={handleSubmitSetBankHour}
          >
            Escala
          </Button>
          <Menu
            disabled={user?.user.type !== UserType.MASTER}
            shadow="md"
            width={200}
          >
            <Menu.Target>
              <Button
                disabled={user?.user.type !== UserType.MASTER}
                color="teal.5"
                ml={8}
                type="submit"
                rightIcon={<IconListDetails />}
              >
                Adicionar
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Tipos de recibo</Menu.Label>
              <Menu.Item
                onClick={() => setPageModal('add-daily-work')}
                icon={<IconClock size={14} />}
              >
                Horário
              </Menu.Item>
              <Menu.Item
                onClick={() => setPageModal('add-work-justification')}
                icon={<IconMedicineSyrup size={14} />}
              >
                Justificativa
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Flex wrap="wrap" justify="start" mb={8}>
          <form
            onSubmit={formFilter.onSubmit((values) => handleFilter(values))}
          >
            <Flex align="center">
              <Select
                searchable
                clearable
                label="Usuário"
                placeholder="selecione o usuário"
                data={userList?.map((item) => ({
                  label: item.username,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('userId')}
              />
              <Select
                clearable
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={userRoleList}
                {...formFilter.getInputProps('role')}
                mb={16}
                mr={8}
              />
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Data Inicial"
                maw={170}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('dateBegin')}
              />
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Data Final"
                maw={170}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('dateEnd')}
              />
              <Button mb={-10} type="submit">
                Filtrar
              </Button>
            </Flex>
          </form>
        </Flex>
        {RenderDataTable}
        <Group mt={8} position="right">
          <Text fw="bold">Total:</Text>
          <Text>{formatBRL(pageTotal?.toFixed(2))}</Text>
        </Group>
      </Flex>
      <Modal
        title="Exportar Recibo."
        opened={pageModal === 'confirm-receipt'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <Group>
          <Text fw="bold">Tudo certo!</Text>
          <Text>O recibo vai aparecer em instantes no seus Downloads.</Text>
        </Group>
        <Group position="right">
          <Button onClick={() => setPageModal(null)}>OK</Button>
        </Group>
      </Modal>
      <Modal
        size="80%"
        title={<Text fw="bold">Recibo: Diárias</Text>}
        opened={pageModal === 'generate-receipt'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <Flex wrap="wrap" align="center" justify="start" mb={8}>
          <form
            onSubmit={receiptListForm.onSubmit((values) =>
              handleFilterReceiptList(values),
            )}
          >
            <Flex align="center">
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Data Inicial"
                maw={170}
                mb={16}
                mr={8}
                {...receiptListForm.getInputProps('dateBegin')}
              />
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Data Final"
                maw={170}
                mb={16}
                mr={8}
                {...receiptListForm.getInputProps('dateEnd')}
              />
              <Select
                required
                withAsterisk
                name="role"
                label="Tipo de usuário"
                placeholder="selecione o tipo e usuário"
                data={userRoleList}
                {...receiptListForm.getInputProps('role')}
                mb={16}
                mr={8}
              />
              <Button mb={-10} type="submit">
                Buscar
              </Button>
            </Flex>
          </form>
        </Flex>
        <DataTable
          fetching={pageLoading}
          minHeight={200}
          height={300}
          noRecordsText="Sem usuários"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={receiptUserList}
          columns={[
            {
              accessor: 'username',
              title: 'Funcionário',
            },
            {
              accessor: 'userRole',
              title: 'Tipo',
              render: ({ role }) => userRoleHumanized[role],
            },
            {
              accessor: 'transportPrice',
              title: 'Horas trabalhadas',
              render: ({ workMinutes }) => {
                const restMinutes = workMinutes % 60;
                const hours = (workMinutes - restMinutes) / 60;

                return `${hours}hr ${restMinutes}min`;
              },
            },
            {
              accessor: 'transportPrice',
              title: 'Valor Diária',
              render: ({ dailyPrice }) => formatBRL(dailyPrice?.toFixed(2)),
            },
            {
              accessor: 'transportPrice',
              title: 'Valor total',
              render: ({ totalPrice }) => formatBRL(totalPrice?.toFixed(2)),
            },
            {
              accessor: '',
              title: '',
              render: ({ id }) => (
                <Checkbox
                  checked={selectedUsersToReceipt.includes(id)}
                  onChange={() => handleSelectUserToReceipt(id)}
                />
              ),
            },
            {
              accessor: '',
              title: '',
              render: ({ isPaid }) =>
                isPaid && (
                  <ThemeIcon title="ja emitido" variant="light" color="green">
                    <IconCheck />
                  </ThemeIcon>
                ),
            },
          ]}
        />
        <Group mt={16} position="right">
          <Button
            disabled={!selectedUsersToReceipt.length}
            onClick={() =>
              handleDailyWorkReceitpExport({
                dateBegin: receiptListForm.values.dateBegin,
                dateEnd: receiptListForm.values.dateEnd,
                role: receiptListForm.values.role,
              })
            }
          >
            Gerar Recibo
          </Button>
        </Group>
      </Modal>
      <Modal
        size="80%"
        title={<Text fw="bold">Recibo: Vale Transporte</Text>}
        opened={pageModal === 'generate-vt'}
        onClose={handleCloseVTModal}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <Grid columns={5}>
          <Grid.Col span={2}>
            <Group position="center">
              <CustomCalendar
                value={selectedDates}
                onChange={(dates) => handleSelectDates(dates)}
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={3}>
            <Container display={selectedDates.length ? 'block' : 'none'}>
              <form
                onSubmit={transportListForm.onSubmit((values) =>
                  handleGetETUsers(values),
                )}
              >
                <Flex align="center">
                  <Select
                    required
                    withAsterisk
                    name="role"
                    label="Tipo de usuário"
                    placeholder="selecione o tipo e usuário"
                    data={userRoleList}
                    {...transportListForm.getInputProps('role')}
                    mb={16}
                    mr={8}
                  />
                  <Button mb={-10} type="submit">
                    Filtrar
                  </Button>
                </Flex>
              </form>
              <DataTable
                fetching={pageLoading}
                minHeight={200}
                height={300}
                noRecordsText="Sem usuários"
                withBorder
                borderRadius="sm"
                striped
                highlightOnHover
                records={etUserList}
                columns={[
                  {
                    accessor: 'username',
                    title: 'Funcionário',
                  },
                  {
                    accessor: 'userRole',
                    title: 'Tipo',
                    render: ({ userRole }) => userRoleHumanized[userRole],
                  },
                  {
                    accessor: 'transportPrice',
                    title: 'Valor VT',
                    render: ({ transportPrice }) => formatBRL(transportPrice),
                  },
                  {
                    accessor: 'transportPrice',
                    title: 'Valor total',
                    render: ({ transportPrice }) => {
                      const sum = Number(
                        Number(transportPrice) * selectedDates.length,
                      )?.toFixed(2);
                      return formatBRL(sum);
                    },
                  },
                  {
                    accessor: 'combined4',
                    title: '',
                    render: ({ id, transportPrice }) => (
                      <Checkbox
                        checked={selectedUsersToVT.some(
                          (item) => item.id === id,
                        )}
                        onChange={() =>
                          handleSelectUserToVT({
                            id,
                            value: Number(
                              Number(transportPrice) * selectedDates.length,
                            )?.toFixed(2),
                          })
                        }
                      />
                    ),
                  },
                  {
                    accessor: 'combined5',
                    title: '',
                    render: ({ transportCreatedAt }) =>
                      dayjsPlugins(transportCreatedAt).format('DD/MM/YYYY') ===
                        dayjsPlugins().format('DD/MM/YYYY') && (
                        <ThemeIcon
                          title="ja emitido"
                          variant="light"
                          color="green"
                        >
                          <IconCheck />
                        </ThemeIcon>
                      ),
                  },
                ]}
              />
              <Group mt={16} position="right">
                <Button onClick={handleEmployeeTransportReceitpExport}>
                  Gerar Recibo
                </Button>
              </Group>
            </Container>
          </Grid.Col>
        </Grid>
        <Text fw="bold">Instruções:</Text>
        <Text>
          1- selecione as datas e logo após dê um check nos usuários que deseja
          gerar o recibo.
        </Text>
        <Text>2- logo aṕos, o recibo será baixado.</Text>
      </Modal>
      <Modal
        size={360}
        title={<Text fw="bold">Editar Horário</Text>}
        opened={pageModal === 'edit-daily-work'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <Text>Funcionário:</Text>
        <Text>{selectedDailyWork?.['username']}</Text>
        <form
          onSubmit={editDailyWorkForm.onSubmit((values) =>
            handleEditDailyWork(values),
          )}
        >
          <Grid columns={2} mb={16}>
            <Grid.Col span={2}>
              <DateFormInput
                form={editDailyWorkForm}
                formKey="begin"
                label="Entrada:"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DateFormInput
                form={editDailyWorkForm}
                formKey="start_lounch"
                label="Inicio Intervalo:"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DateFormInput
                form={editDailyWorkForm}
                formKey="end_lounch"
                label="Fim Intervalo:"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DateFormInput
                form={editDailyWorkForm}
                formKey="finish"
                label="Saída:"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput
                required
                withAsterisk
                label="Valor da diária"
                placeholder="digite o valor"
                type="text"
                value={editDailyWorkForm.values.price}
                onChange={(e) =>
                  editDailyWorkForm.setFieldValue(
                    'price',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                label="Observações"
                placeholder="obs sobre diária"
                {...editDailyWorkForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button type="submit" onClick={() => setPageModal(null)}>
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={360}
        title={<Text fw="bold">Adicionar Horário</Text>}
        opened={pageModal === 'add-daily-work'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form onSubmit={addDailyWorkForm.onSubmit(handleAddDailyWork)}>
          <Grid columns={2} mb={16}>
            <Grid.Col span={2}>
              <Select
                withAsterisk
                required
                searchable
                clearable
                label="Usuário"
                placeholder="selecione o usuário"
                data={userList?.map((item) => ({
                  label: item.username,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...addDailyWorkForm.getInputProps('user')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DateFormInput
                form={addDailyWorkForm}
                formKey="begin"
                label="Entrada:"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DateFormInput
                form={addDailyWorkForm}
                formKey="start_lounch"
                label="Inicio Intervalo:"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DateFormInput
                form={addDailyWorkForm}
                formKey="end_lounch"
                label="Fim Intervalo:"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <DateFormInput
                form={addDailyWorkForm}
                formKey="finish"
                label="Saída"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                label="Observações"
                placeholder="obs sobre diária"
                {...addDailyWorkForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button loading={addDailyWorkManuallyLoader} type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={360}
        title={<Text fw="bold">Adicionar Justificativa</Text>}
        opened={pageModal === 'add-work-justification'}
        onClose={() => {
          setPageModal(null);
          addDailyWorkJustificationForm.reset();
          setSelectedDates([]);
        }}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form
          onSubmit={addDailyWorkJustificationForm.onSubmit(
            addDailyWordJustification,
          )}
        >
          <Grid columns={2} mb={16}>
            <Grid.Col span={2}>
              <Select
                withAsterisk
                required
                searchable
                clearable
                label="Usuário"
                placeholder="selecione o usuário"
                data={userList?.map((item) => ({
                  label: item.username,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                {...addDailyWorkJustificationForm.getInputProps('user')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CustomCalendar
                value={selectedDates}
                onChange={(dates) => setSelectedDates(dates)}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Autocomplete
                required
                withAsterisk
                label="Justificativa"
                placeholder="comece a digitar.."
                data={workJustification}
                mb={16}
                mr={8}
                {...addDailyWorkJustificationForm.getInputProps(
                  'justification',
                )}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button loading={addDailyWorkManuallyLoader} type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={360}
        title={<Text fw="bold">Editar Justificativa</Text>}
        opened={pageModal === 'edit-work-justification'}
        onClose={() => handleCloseModal()}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form
          onSubmit={editDailyWorkJustificationForm.onSubmit((values) =>
            editDailyWordJustification(values),
          )}
        >
          <Grid columns={2} mb={16}>
            <Grid.Col span={2}>
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Data"
                maw={170}
                mb={16}
                mr={8}
                {...editDailyWorkJustificationForm.getInputProps('begin')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Autocomplete
                label="Justificativa"
                placeholder="comece a digitar.."
                data={workJustification}
                mb={16}
                mr={8}
                {...editDailyWorkJustificationForm.getInputProps(
                  'justification',
                )}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button loading={editDailyWorkJustificationLoader} type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
